<template>
  <div style="padding-top: 20px">
    <div class="count">
      <div><img src="../../../assets/home_img/1.png" alt="" /></div>
      <div class="count_btn">
        <el-button :type="type == 1 ? 'primary' : ''" @click="railwayClick(1)"
          >铁路运价试算</el-button
        >
        <el-button :type="type == 2 ? 'primary' : ''" @click="railwayClick(2)"
          >公路运价试算</el-button
        >
      </div>
    </div>
    <Railway class="RailwayCalculation" v-if="type == 1"></Railway>
    <Highway class="HighwayCalculation" v-if="type == 2"></Highway>
  </div>
</template>

<script>
import Railway from "./componentns/Railway.vue";
import Highway from "./componentns/Highway.vue";
export default {
  components: {
    Railway,
    Highway,
  },
  data() {
    return {
      type: 2,
    };
  },
  created() {
    this.type = this.$route.query.type;
  },
  watch: {
    "$route.query.type": {
      handler(val) {
        this.type = val;
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {
    railwayClick(e) {
      this.$router.push({
        path: "/railway",
        query: {
          type: e,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.count {
  // max-width: 1200px;
  width: 65%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  border: 1px solid #ccc;

  .count_btn {
    margin: 10px auto;
  }
}
.RailwayCalculation,
.HighwayCalculation {
  margin: 14px auto;
}
</style>
